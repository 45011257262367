<template>
     <v-container class="my-5" >
          <v-row v-if="user">
               <v-col cols="12">
                   <v-card >
                        <v-card-title primary-title>
                             <div>
                                  <h1 class="mb-2">{{$t('user.dashboard.title')}}</h1>
                                  <p class="mb-0" v-if="user.profile.fullname">{{user.profile.fullname}}</p>
                                  <p class="mb-0" v-else>{{user.email}}</p>
                             </div>
                        </v-card-title>
                   </v-card>
               </v-col>
               <v-col cols="12" v-if="this.alert.visible">
                    <v-alert
                    
                    border="left"
                    type="warning"
                    icon="mdi-alert"
                    prominent
                    >

                        <h3>{{ $t(this.alert.title) }}</h3>
                       {{ $t(this.alert.text) }}
                    </v-alert>
               </v-col>
               <v-col cols="12" md="4" v-if="user.qrcode">
                   <v-card class="mb-3">
                        <v-card-title primary-title>
                             <div>
                                  <h3>{{$t('commons.points')}}</h3>
                             </div>
                        </v-card-title>
                        <v-card-text class="text-center">
                             <v-progress-circular 
                              :rotate="-90"
                              color="primary" 
                              :value="user.points.percent"
                              :size="200"      
                              :width="15"
                              block
                              >
                              <span class="h1 display-3">{{user.points.points}}</span>
                             </v-progress-circular>
                            
                            <br>
                            <span>{{$t('points.level.'+user.points.level)}}</span>
                        </v-card-text>
                   </v-card>
                   <v-card class="mb-3" >
                        <v-card-title primary-title>
                             <div>
                                  <h3>{{$t('commons.qrcode')}}</h3>
                             </div>
                        </v-card-title>
                        <v-card-text>
                             <p class="text-center display-1">{{user.qrcode.title}}</p>
                             <v-img :src="user.qrcode.url.image"></v-img>
                        </v-card-text>
                   </v-card>
                   <v-card class="mb-3" :loading="loading">
                        <v-card-title primary-title>
                             <div>
                                  <h3>{{$t('commons.coupons')}}</h3>
                             </div>
                        </v-card-title>
                        <v-card-text>
                              <json-debug v-if="coupons" :objs="[{coupons: coupons}]"></json-debug>
                              <template v-if="meta.last_page > 1">
                                   <json-debug v-if="meta" :objs="[{meta: meta}]"></json-debug>
                              </template>
                        </v-card-text>
                   </v-card>
               </v-col>
               <v-col cols="12" md="8">
                    <v-card class="mb-3">
                        <v-card-title primary-title>
                             <div>
                                  <h3>{{$t('commons.user')}}</h3>
                             </div>
                             <v-spacer></v-spacer>
                              <v-btn icon @click="edit_settings = !edit_settings" color="primary">
                                   <v-icon v-if="!edit_settings">mdi-pencil</v-icon>
                                   <v-icon v-if="edit_settings">mdi-close</v-icon>
                              </v-btn>
                        </v-card-title>
                        <v-card-text v-if="!edit_settings">
                             <simple-table :table="settings" ></simple-table> 
                        </v-card-text>
                        <v-card-text v-if="edit_settings">
                            <form-settings :edit_settings.sync="edit_settings" :settings="settings"></form-settings>
                        </v-card-text>
                   </v-card>
                   <v-card class="mb-3">
                        <v-card-title primary-title>
                             <div>
                                  <h3>{{$t('commons.profile')}}</h3>
                             </div>
                             <v-spacer></v-spacer>
                              <v-btn icon @click="edit_profile = !edit_profile" color="primary">
                                   <v-icon v-if="!edit_profile">mdi-pencil</v-icon>
                                   <v-icon v-if="edit_profile">mdi-close</v-icon>
                              </v-btn>
                        </v-card-title>
                        <v-card-text v-if="!edit_profile">
                             <simple-table :table="user.profile" ></simple-table> 
                        </v-card-text>
                        <v-card-text v-if="edit_profile">
                            <form-profile :edit_profile.sync="edit_profile" :profile="user.profile"></form-profile>
                        </v-card-text>
                   </v-card>
                   <v-card class="mb-3">
                        <v-card-title primary-title>
                             <div>
                                  <h3>{{$t('commons.privacy')}}</h3>
                             </div>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="edit_privacy = !edit_privacy" color="primary">
                                   <v-icon v-if="!edit_privacy">mdi-pencil</v-icon>
                                   <v-icon v-if="edit_privacy">mdi-close</v-icon>
                              </v-btn>
                        </v-card-title>
                        <v-card-text v-if="!edit_privacy">
                             <simple-table :table="user.privacy" ></simple-table> 
                        </v-card-text>
                        <v-card-text v-if="edit_privacy">
                             <form-privacy :edit_privacy.sync="edit_privacy" :privacy="user.privacy"></form-privacy>
                        </v-card-text>
                   </v-card>
               </v-col>
          </v-row>
          <div v-if="!user">
               <h3>ERROR NO USER FOUND</h3>
               <v-btn color="success" @click="getUser()">retry</v-btn>     
          </div>
     </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import FormProfile from "@/components/User/Profile/FormProfile";
import FormPrivacy from "@/components/User/Profile/FormPrivacy";
import FormSettings from '@/components/User/Profile/FormSettings.vue';

export default {
     computed: {
     ...mapGetters({
          user: 'user/user',
          coupons: 'userCoupons/coupons',
          meta: 'userCoupons/meta',
      }),
     },
     components:{
          'form-profile':FormProfile,
          'form-privacy':FormPrivacy,
          'form-settings':FormSettings,
     },
     beforeMount(){
          this.settings = {
               language:  this.user.country+'-'+this.user.language,
               email:  this.user.email,
               email_verified_at:  this.user.email_verified_at,
               email_is_verified: ( this.user.email_verified_at) ? true : false,
               phone: this.user.phone,
               phone_verified_at:  this.user.phone_verified_at,
               phone_is_verified:  ( this.user.phone_verified_at) ? true : false,
          }
          this.alert = {
               visible: (this.$moment(this.user.privacy.updated_at).add(1,'year') <= this.$moment().subtract(30,'days')),
               title: 'privacy.alert.title',
               text: 'privacy.alert.text',
          }
          if(!this.user){
               this.$store.dispatch('user/getUser')
          }
          if(!this.coupons){
               this.$store.dispatch('userCoupons/getUserCoupons')
          }
          this.loading = false;
     },
     data: () => ({
          edit_profile: false,
          edit_settings: false,
          edit_privacy: false,
          loading: true,
     }),
     methods:{
          getUser(){
               this.$store.dispatch('user/getUser')
          }
     }
}
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>