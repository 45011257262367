<template>
     <div>
          <v-row>
               <v-col cols="12">
                    <h2>Edit</h2>
               </v-col>
               <v-col cols="2">
                              <v-select
                                   v-if="titles"
                                   :items="titles"
                                   v-model="title"
                                   :label="$t('attributes.title_name.label')"
                                   item-text="title"
                                   item-value="title"
                                   return-object
                                   :error-messages="errors.title_name"
                                   @change="changeTitle"
                              ></v-select>
               </v-col>
               <v-col cols="5">
                    <v-text-field 
                         type="text"
                         v-model="profile.firstname"
                         :label="$t('attributes.firstname.label')"
                         name="firstname"
                         required
                         :hint="$t('attributes.firstname.hint')"
                         :error-messages="errors.firstname"
                         @focus="errors.firstname = []"
                    >
                    </v-text-field>
               </v-col>
               <v-col cols="5">
                    <v-text-field 
                         type="text"
                         v-model="profile.lastname"
                         :label="$t('attributes.lastname.label')"
                         name="lastname"
                         required
                         :hint="$t('attributes.firstname.hint')"
                         :error-messages="errors.lastname"
                         @focus="errors.lastname = []"
                    >
                    </v-text-field>
               </v-col>
               <v-col cols="12" > 
                    {{ profile.date_of_birth }}
                    <datepicker 
                              :label="$t('attributes.date_of_birth.label')"
                              :date.sync="profile.date_of_birth"
                              :min="mindate"
                              :max="maxdate"
                              :errors.sync="errors.date_of_birth"
                    ></datepicker>
               </v-col>
               <v-col cols="12">
                    <v-row>
                         <v-col cols="3">
                              <v-text-field 
                                   type="text"
                                   v-model="profile.zip_code"
                                   :label="$t('attributes.zip_code.label')"
                                   name="zip_code"
                                   required
                                   :hint="$t('attributes.zip_code.hint')"
                                   :error-messages="errors.zip_code"
                                   @focus="errors.zip_code = []"
                              >
                              </v-text-field>
                         </v-col>
                         <v-col>
                              <v-text-field 
                                   type="text"
                                   v-model="profile.city"
                                   :label="$t('attributes.city.label')"
                                   name="city"
                                   required
                                   :hint="$t('attributes.city.hint')"
                                   :error-messages="errors.city"
                                   @focus="errors.city = []"
                              >
                              </v-text-field>
                         </v-col>
                    </v-row>
               </v-col>

               <v-col cols="12">
                    <v-btn :loading="loading" color="primary" block @click="saveProfile">SAVE</v-btn>
               </v-col>

               <!-- <v-col cols="12">
                    <json-debug v-if="errors"  :objs="[{errors: errors}]"></json-debug>
                    <json-debug v-if="profile" :objs="[{profile: profile}]"></json-debug>
               </v-col> -->
          </v-row>
     </div>
</template>
<script>
import {mapGetters} from "vuex";
import datepicker from '../../Commons/FormValues/datepicker.vue';

export default {
  components: { datepicker },
     name: "profile-form",
     computed: {
     ...mapGetters({
          titles: 'general/titles',
          prefixes: 'general/prefixes'
      }),
     },
     props:{
          profile:{
               type: Object,
               required:true
          }
     },
     beforeMount(){
          if(!this.titles){
               console.log('No Titles Found')
               this.$store.dispatch('general/getTitles', this.$i18n.locale)
          }
          if(!this.prefixes){
               console.log('No Prefixes Found')
               this.$store.dispatch('general/getPrefixes')
          }
          this.maxdate = this.$moment().subtract('16','years').format('YYYY-MM-DD')
          this.mindate = this.$moment().subtract('99','years').format('YYYY-MM-DD')
          // this.profile.date_of_birth = (this.profile.date_of_birth) ? this.$moment(this.profile.date_of_birth).format('YYYY-MM-DD') : this.maxdate
          this.title = this.profile.title_name
     },
     data: () => ({
          errors: {},
          loading: false,
          maxdate: null,
          mindate: null,
     }),
     methods:{
          textItem: item => item.country + ' (' + item.prefix + ')',
          changeTitle(item){
               this.profile.gender = item.gender;
               this.profile.title_name = item.title;
          },
          saveProfile(){
               this.loading = true;
               this.$store.dispatch('user/updateUserProfile',this.profile).then((response) => {
                    console.log('updateUserProfile success!',response)
                    this.loading = false;
                    this.$emit('update:edit_profile', false)
               })
               .catch((err) => {
                    console.error('LOGIN ERROR!',err)
                    this.message = err.response.data.message
                    this.errors = err.response.data.errors
                    this.loading = false;
               }) 
          }
     }
}
</script>