<template>
     <v-container class="my-5" >
          <v-row>
               <v-col cols="12">
                    <v-row>
                         <v-col>
                              <v-card>
                                   <v-card-title primary-title>
                                       <h1>{{ $t('commons.dashboard')}}</h1>
                                   </v-card-title>
                                   
                              </v-card>
                         </v-col>
                    </v-row>
               </v-col>
               <v-col cols="12">
                    <v-row>
                         <v-col v-for="role in roles" :key="role" cols="12" md="4" >
                              <v-card v-if="role != 'user'">
                                   <v-card-title primary-title>
                                        <v-avatar
                                             size="small"
                                             color="white"
                                             icon
                                             class="mx-3"
                                        >
                                             <v-icon color="primary">{{$t('roles.'+role+'.icon')}}</v-icon>
                                        </v-avatar>
                                        {{$t('roles.'+role+'.title')}}
                                   </v-card-title>
                                   <v-card-text>
                                        {{$t('roles.'+role+'.description')}}
                                   </v-card-text>
                                   <v-divider></v-divider>
                                   <v-card-text>
                                        <v-btn :to="{name: role+'.dashboard'}" color="primary">{{$t('commons.dashboard')}} {{$t('roles.'+role+'.title')}}</v-btn>
                                   </v-card-text>
                              </v-card>
                         </v-col>
                    </v-row>
               </v-col>
          </v-row>
     </v-container>
</template>

<script>
import {mapGetters} from "vuex";

export default {
     computed: {
     ...mapGetters({
          isAuthenticated: 'auth/isAuthenticated',
          roles: 'auth/roles'
      }),
     }
}
</script>