<template>
          <div >
               <v-row>
               <v-col cols="12">
                    <h2>Edit</h2>
               </v-col>
               <v-col cols="12">
                    <v-row>
                         <v-col cols="12">
                              <v-row>
                                   <v-col cols="8">
                                        <v-text-field 
                                             type="text"
                                             v-model="settings.email"
                                             :label="$t('attributes.email.label')"
                                             name="email"
                                             required
                                             :hint="$t('attributes.email.hint')"
                                             :error-messages="errors.email"
                                             @focus="errors.email = []"
                                        >
                                        </v-text-field>
                                   </v-col>
                                   <v-col>
                                        <v-btn block @click="emailChange" color="primary">SAVE NEW EMAIL</v-btn>
                                   </v-col>
                              </v-row>
                         </v-col>
                         <v-col cols="12">
                              <v-row>
                                   <v-col>
                                   {{$t('attributes.email_is_verified.label')}}
                                   <v-icon class="mx-2" color="green accent-4" v-if="settings.email_is_verified">mdi-check-circle</v-icon>
                                   <v-icon class="mx-2" color="red accent-4" v-if="!settings.email_is_verified">mdi-cancel</v-icon>
                                   <br>
                                   </v-col>
                                   <v-col>
                                        <v-btn block color="primary" @click="resendEmail" v-if="!settings.email_is_verified">ResendEmail</v-btn>
                                   </v-col>
                              </v-row>
                         </v-col>
                    </v-row>
               </v-col>
               <v-col cols="12">
                    <v-divider></v-divider>
               </v-col>
               <v-col cols="12">
                    <v-row>
                         <v-col cols="12">
                              <v-row>
                                   <v-col cols="8">
                                        <v-text-field 
                                             type="number"
                                             v-model="settings.phone"
                                             :label="$t('attributes.phone.label')"
                                             name="phone"
                                             required
                                             :hint="$t('attributes.phone.hint')"
                                             :error-messages="errors.phone"
                                             @focus="errors.phone = []"
                                        >
                                        </v-text-field>
                                   </v-col>
                                   <v-col>
                                        <v-btn block @click="phoneChange" color="primary">SAVE NEW PHONE</v-btn>
                                   </v-col>
                              </v-row>
                         </v-col>
                         <v-col cols="12">
                              <v-row>
                                   <v-col>
                                   {{$t('attributes.phone_is_verified.label')}}
                                   <v-icon class="mx-2" color="green accent-4" v-if="settings.phone_is_verified">mdi-check-circle</v-icon>
                                   <v-icon class="mx-2" color="red accent-4" v-if="!settings.phone_is_verified">mdi-cancel</v-icon>
                                   <br>
                                   </v-col>
                                   <v-col v-if="!smsSent">
                                        <v-btn v-if="!settings.phone_is_verified" block color="primary" @click="resendSms">ResendSMS</v-btn>
                                   </v-col>
                                   <v-col v-else>
                                                  <v-text-field 
                                                       type="text"
                                                       v-model="token"
                                                       :label="$t('attributes.token.label')"
                                                       name="token"
                                                       required
                                                       :hint="$t('attributes.token.hint')"
                                                       :error-messages="errors.token"
                                                       @focus="errors.token = []"
                                                  >
                                                  </v-text-field>
                                        <v-btn block color="primary" @click="verifySmsToken">verifySmsToken</v-btn>
                                   </v-col>
                              </v-row>
                         </v-col>
                    </v-row>
               </v-col>
               <v-col cols="12">
                    <v-divider></v-divider>
               </v-col>
               
               </v-row>
          </div>
</template>

<script>
export default {
     props:{
          settings:{
               type: Object,
               required:true
          }
     },
     data: () => ({
          errors: {},
          loading: false,
          smsSent: false,
          token: null,
     }),
     methods:{
          saveEmail(data){
               this.loading = true;
               this.$store.dispatch('user/updateUserSettings', data).then((response) => {
                    console.log('updateUserSettings success!',response)
                    this.loading = false;
                    this.logout();
               })
               .catch((err) => {
                    console.error('updateUserSettings ERROR!',err)
                    this.message = err.response.data.message
                    this.errors = err.response.data.errors
                    this.loading = false;
               }) 
          },
          savePhone(data){
               this.loading = true;
               this.$store.dispatch('user/updateUserSettings', data).then((response) => {
                    console.log('updateUserSettings success!',response)
                    this.loading = false;
                    this.settings.phone_is_verified = false
                    this.smsSent = true
               })
               .catch((err) => {
                    console.error('updateUserSettings ERROR!',err)
                    this.message = err.response.data.message
                    this.errors = err.response.data.errors
                    this.loading = false;
               }) 
          },
          verifySmsToken(){
               console.log('Verified Token',this.token)
               var data = {'token':this.token}
               this.$store.dispatch('register/verifyPhone',data)
               .then((response) => {
                         this.sent = response.data.message
                         console.log('verifyPhone success!',response)
                         this.settings.phone_is_verified = true;
                    })
               .catch((err) => {
                         console.error('Verification TOKEN SMS ERROR!',err)
                         this.message = err.response.data.message
                         this.errors = err.response.data.errors
                         this.loading = false;
                    }) 

          },
          emailChange(){
               var data = {'email':this.settings.email}
               if(confirm("you logout!")){
                    this.saveEmail(data)
               }
          },
          phoneChange(){
               var data = {'phone':this.settings.phone}
               this.savePhone(data)
          },
          resendEmail(){
               this.loading = true;
               var data = {'email':this.settings.email}
               this.$store.dispatch('register/resend',data)
               .then((response) => {
                         this.sent = response.data.message
                         console.log('SENT Verification success!',response)
                         this.loading = false;
                    })
               .catch((err) => {
                         console.error('SENT Verification ERROR!',err)
                         // this.message = err.response.data.message
                         this.errors = err.response.data.errors
                         this.loading = false;
                    })   
          },
          resendSms(){
               this.loading = true;
               var data = {'phone':this.settings.phone}
               this.$store.dispatch('register/resendSms',data)
               .then((response) => {
                         this.sent = response.data.message
                         console.log('SENT Verification Token success!',response)
                         this.loading = false;
                         this.smsSent = true
                    })
               .catch((err) => {
                         console.error('SENT Verification ERROR!',err)
                         // this.message = err.response.data.message
                         this.errors = err.response.data.errors
                         this.loading = false;
                    })   
          },
          logout(){
               this.$store.dispatch('auth/logout')
               .then((response) => {
                         console.log('logout success!',response)
                         this.$router.push({name:'Login'})
               })
          }
     }
}
</script>
