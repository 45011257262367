<template>
          <div >
               <v-row>
               <v-col cols="12">
                    <h2>Edit</h2>
               </v-col>
               <v-col cols="12">
               <v-simple-table>
                    <tbody>
                    <template v-for="(value, field) in privacy">
                    <tr :key="field" v-if="field != 'updated_at'">
                         <th>
                              <p class="text-start p-0">
                              <b>{{$t('attributes.'+field+'.label')}}</b><br>
                              {{$t('privacy.'+field+'.text')}}
                              
                              <template danger v-if="errors[field]">
                                   <span v-for="error in errors[field]" :key="error" class="error--text"><br>{{error}}</span>
                              </template>
                              </p>
                         </th>
                         <td>
                             <v-checkbox
                                   v-model="privacy[field]"
                                   value
                              ></v-checkbox>
                         </td>
                    </tr>
                    </template>
                    </tbody>
               </v-simple-table>
               </v-col>
               <v-col cols="12">
                    <v-btn color="primary" :loading="loading" @click="savePrivacy" block>save</v-btn>
               </v-col>
               </v-row>
          </div>
</template>

<script>
export default {
     props:{
          privacy:{
               type: Object,
               required:true
          }
     },
     data: () => ({
          errors: {},
          loading: false,
          maxdate: null,
          mindate: null,
     }),
     methods:{
          savePrivacy(){
               this.loading = true;
               this.$store.dispatch('user/updateUserPrivacy',this.privacy).then((response) => {
                    console.log('updateUserPrivacy success!',response)
                    this.loading = false;
                    this.$emit('update:edit_privacy', false)
               })
               .catch((err) => {
                    console.error('LOGIN ERROR!',err)
                    this.message = err.response.data.message
                    this.errors = err.response.data.errors
                    this.loading = false;
               }) 
          }
     }
}
</script>
